  <!-- wrapper -->
  <div class="wrapper col" style="height: 99.5vh;">
    <div class="row LoginBg">

      <div class="col lftImg"></div>
      <div class="col signTextBg">
        <div class="d-flex">
          <a href=""><img src="/images/logo1.svg"></a>
          <div class="signText">
            <div class="textBox">
              Our mission is to 
make lives easier for 
patients and doctors
            </div>
          </div>
          <div class="copyright-inner">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. {{version}} </div>
        </div>
      </div>

      <!-- logIn-Rght -->
      <div class="col logIn-Rght">
        <div class="loginCont credentials-Cont">
          <h1>New Credentials</h1>
          <p>Your identity has been verified!<br>
            Set your new password</p>
          <div class="loginForm">
            <form [formGroup]="pswForm">
              <div class="form-group">
                <label>Current password</label>
                <input type="password" formControlName="passwordcurrent" class="form-control">
                <mat-error *ngIf="SubmitLoader && f.passwordcurrent.errors">Current password is required</mat-error>

              </div>
            <div class="form-group">
              <label>New password</label>
              <input type="password" formControlName="password" class="form-control">
              <mat-error *ngIf="SubmitLoader && f.password.errors">New password is required</mat-error>

            </div>
            <div class="form-group">
              <label>Confirm password</label>
              <input type="password" formControlName="confirmpassword" class="form-control">
              <mat-error *ngIf="SubmitLoader && f.confirmpassword.errors">Confirm password is required</mat-error>

            </div>
            <button type="button" (click)="UpdatePassword()" class="common-btn">Update</button>
            </form>
            <span style="color: crimson; font-family: monospace;">{{ errorlog }}</span>
          </div>
        </div>
      </div>
      <!-- logIn-Rght -->

    </div>
  </div>
  <!-- wrapper -->