
<div class="loderBG" *ngIf="Loader"><img src="/images/loading.gif"></div>
<!-- pageHeading -->
<div class="pageHeading">
  <h1>Welcome {{fullName}}</h1>
  <!-- filter-top -->
  <div class="col filter-top">
    <div class="input-group m-0 radius_7 range_picker">
      <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-icon matDatepickerToggleIcon (click)="clearDate($event)">clear</mat-icon>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker
                               [calendarHeaderComponent]="DateRangeHeaderComponent"></mat-date-range-picker>
      </mat-form-field>
    </div>
  </div>
  <!-- filter-top -->
</div>
<!-- pageHeading -->


<div class="row">
  <div class="col-lg latestOrder" *ngIf="IsOrderListPermission">
    <!-- box -->
    <div class="box">
      <!-- boxHead -->
      <div class="boxHead">
        <h4>Latest Orders</h4>
        <a [routerLink]="['/orders/list']" class="btn allBtn">all orders<img src="/images/arwGreen.svg"></a>
      </div>
      <!-- boxHead -->
      <!-- table-responsive -->
      <div class="table-responsive mt-20">
        <table class="table align-middle">
          <thead>
            <tr>
              <th>Order #</th>
              <th>Name</th>
              <th>Order Status</th>
              <th>Tracking Number</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of OrderListView;">
              <td>{{element.orderReferenceId}}</td>
              <td>{{element.patientName}}</td>
              <td><span class="status New">{{element.orderStatus}}</span></td>
              <td>{{SetValue(element.orderTrackingno)}}</td>
              <td class="action">
              </td>
            </tr>

          </tbody>
        </table>
        <mat-paginator [length]="resultsLength" [pageSizeOptions]="[10, 20, 50, 100]" (page)="getServerData($event)"></mat-paginator>
      </div>
      <!-- table-responsive -->


    </div>
    <!-- box -->
  </div>

  <div class="col-lg myTaskBg" *ngIf="IsTaskListPermission">
    <!-- box -->
    <div class="box">
      <!-- boxHead -->
      <div class="boxHead">
        <h4>My Tasks</h4>
        <a [routerLink]="['/tasks/list']" class="btn allBtn">all tasks<img src="/images/arwGreen.svg"></a>
      </div>
      <!-- boxHead -->
      <!-- table-responsive -->
      <div class="table-responsive mt-20">
        <table class="table align-middle">
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let element of TaskListView;">
              <td>{{element.taskName}}</td>
              <td>
                <div class="radioBg">
                  <div class="radiobtn checkbox">
                    <input type="checkbox" id="element.taskId" *ngIf="element.taskStatus=='Completed'" [checked]="true" name="" value="" />
                    <input type="checkbox" id="element.taskId" *ngIf="element.taskStatus!='Completed'" disabled="disabled" name="" value="" />
                    <label for="element.taskId">{{element.taskStatus}}</label>
                  </div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
      <!-- table-responsive -->

    </div>
    <!-- box -->
  </div>
</div>



