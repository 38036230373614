
 <!-- wrapper -->
 <div id="wrapper" class="wrapper col">
  <!-- Ins-headersec -->
  <div id="Ins-headersec">
    <nav class="navbar navbar-expand-lg">
      <!-- top1row -->
      <div class="col-12 top1row">
        <a class="navbar-brand"><img src="/images/logo.svg" class="toggleHide"></a>

        <div class="col tp-inRghtBg">
          <p> Our mission is to make lives easier for patients and doctors</p>
          <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseMenu" aria-controls="collapseMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <!-- tp-inRght -->
          <div class="tp-inRght">
            <li class="prfl dropdown">
              <a href="#" class="dropdown-toggle" role="button" id="" data-bs-toggle="dropdown" aria-expanded="false">
                <!-- <span><img src="/images/prflImg.png"></span> -->
                <ngx-avatar [style]="customStyleAvatar" size="25" [name]="fullName" ></ngx-avatar>
              </a>
              <ul class="dropdown-menu" aria-labelledby="">
                <div class="HeaderSec">
                  <!-- <span><img src="/images/prflImg.png"></span> -->
                  <ngx-avatar [style]="customStyleAvatar" size="50" [name]="fullName" ></ngx-avatar>
                  <h3> {{fullName}} </h3>
                  <li><i><a style="font-size: 13px;cursor: pointer;" class="dropdown-item" (click)="resetpassword()">Reset Password</a></i></li>
                </div>
                <div class="tp-ftr">
                  <!-- <li><a class="dropdown-item" href="#">Profile Settings</a></li> -->
                  <li><a class="dropdown-item" [routerLink]="['/login']">Sign out</a></li>
                </div>
              </ul>
            </li>
          </div>
          <!-- tp-inRght -->
        </div>
      </div>
      <!-- top1row -->
      <app-main-nav></app-main-nav>
    </nav>
  </div>
  <!-- Ins-headersec -->

  <!-- content -->
  <div class="content col">
    <!-- content-wrapper -->
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
    <!-- content-wrapper -->

  </div>
  <!-- content -->
</div>
<!-- wrapper -->
  <div class="copyright-inner to_bottom">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. {{version}} </div>
