<router-outlet></router-outlet>
<button type="button" id="openModalButton" class="btn btn-info btn-lg" [hidden]="true" (click)="openModal()">click to open</button>

<div class="modal cstmModal orderModal" tabindex="-1" role="dialog" id="idealModal" [ngStyle]="{'display':display}">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="cstmModalBody">
                <div class="orderBox" *ngIf="idleState=='Timed out!'">
                    <div mat-dialog-title style="text-align: center;"><img src="/images/order-icon1.svg"></div>
                    <h5>You have been for too long?</h5>
                    <p style="font-size: 16px;color: red;">Time Out Expired ,Please Login Again!</p>
                    <div class="btnsBg">
                        <button class="btn common-btn" (click)="onCloseHandled()">Okay!</button>
                      </div>
                </div>
                <div class="orderBox" *ngIf="idleState!='Timed out!'">
                    <div mat-dialog-title style="text-align: center;"><img src="/images/order-icon1.svg"></div>
                    <h5>Due to inactivity your session has been locked out</h5>
                    
                    <p style="font-size: 14px;"><b>{{idleState}}</b></p>
                    <p> Would you like to logout?</p>
                    <div class="btnsBg">
                      <button class="btn common-btn" (click)="stay()">No, Go Back</button>
                      <button class="btn cancelBtn" (click)="logout()">Yes, Logout</button>
                    </div>
                  </div>
          </div>

      </div>
    </div>
</div>

<div class="modal cstmModal orderModal" [ngStyle]="{'display':displayQR}" id="qrModal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- cstmModalBody -->
        <div class="cstmModalBody">
          <!-- cstmModalCont -->
          <div class="cstmModalCont">
            <div class="orderBox">
              <div mat-dialog-title style="text-align: right;">
                <button class="btn cancelBtn" (click)="onCloseHandledQR()">Esc</button>
              </div>
              <h5>SCAN QR CODE</h5>                    
              <p><i><b>{{clearqr}}</b></i></p>
              <input type="text" #qrcode class="form-control" tabindex="1"  [ngClass]="" placeholder="Waiting for scan" />
            </div>
          </div>
        </div>
    </div>
  </div> 
</div>
    
