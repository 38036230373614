<div class="wrapper col" style="height: 99.5vh;">
  <div class="row LoginBg">

    <div class="col lftImg"></div>
    <div class="col signTextBg">
      <div class="d-flex">
        <a href=""><img src="/images/logo1.svg"></a>
        <div class="signText">
          <div class="textBox">
            Our mission is to 
make lives easier for 
patients and doctors
          </div>
        </div>
        <div class="copyright-inner">Copyright © {{currentyear}} Inservio Home. All Rights Reserved. {{version}} </div>
      </div>
    </div>


    <!-- logIn-Rght -->
    <div class="col logIn-Rght">
      <div class="loginCont">
        <h1>Login to your account</h1>
        <div class="loginForm">
          <form [formGroup]="LogInForm">
            <div class="form-group">
              <label>Username</label>
              <input type="text" class="form-control" tabindex="1" formControlName="username"  [ngClass]="{ 'is-invalid': SubmitLoader && f.username.errors }" placeholder="Username">
              <mat-error *ngIf="SubmitLoader && f.username.errors">Username is required</mat-error>
            </div>
            <div class="form-group">
              <label>Password <a [routerLink]="['/forgetpassword']">Forgot Password?</a></label>
              <input type="password" class="form-control" tabindex="2" formControlName="password"  [ngClass]="{ 'is-invalid': SubmitLoader && f.password.errors }" placeholder="Password">
              <mat-error *ngIf="SubmitLoader && f.password.errors">Password is required</mat-error>
            </div>
            <button type="submit" *ngIf="!SubmitLoader" tabindex="3" class="common-btn" (click)="Login()">Login</button>
            <a *ngIf="SubmitLoader" class="btn common-btn">
              <span class="btnLoader"><i class="fa fa-spinner fa-spin"></i></span>
            </a>

            <div class="radioBg">
              <div class="radiobtn">
                <input type="checkbox" id="Remember" name="" formControlName="rememberMe" />
                <label for="Remember">Remember Me</label>
              </div>
            </div>
            <div *ngIf="error" class="alert alert-danger">{{error}}</div>
            <!-- <div *ngIf="f.username.invalid && (f.username.dirty || f.username.touched)"
            class="alert"> <div *ngIf="f.username.errors?.required"> Name is required.</div></div>
            <div *ngIf="f.password.errors?.required"> password is required.</div> -->
          </form>
        </div>
      </div>
    </div>
    <!-- logIn-Rght -->

  </div>
</div>
