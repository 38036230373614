const MEDAPI = {
  DEV: 'https://medapi.inserviohome.com.au',
  STAGING: 'https://medapi.inserviohome.com.au',
  UAT: 'https://medapi.uat.inserviohome.com.au',
  PRODUCTION: 'https://medapi.prod.inserviohome.com.au'
};

const InservioPathURL = {

  LOCAL: 'https://localhost:5001',
  DEV: 'https://serviceapi.dev.inserviohome.com.au',
  STAGING: 'https://serviceapi.stage.inserviohome.com.au',
  UAT: 'https://api.uat.inserviohome.com.au',
  PRODUCTION: 'https://api.prod.inserviohome.com.au'
};

const SMARTWAYSAPI = {
  URL: 'https://smartwayslogistics.com/backend/web/api/v3/client-web'
};

const URL = {
  API: InservioPathURL.STAGING,
  MedAPI: MEDAPI.STAGING,
  SMARTAPI: SMARTWAYSAPI.URL
};

console.log(URL.API);
export const InservioAPI = {
  InservioURL: URL
};


export const GOOGLEAPI = {
  KEY : "AIzaSyDC2-zc6vwE-zLnU70k6--u-QHq0sR2Mmo"
}

export const PageSizes = {
  Dashboard:100,
  Orders:100,
  Patiens:100,
  History:100,
  Programs:100,
  Task:100,
  Therapy:100
}

export const IsEnvironmentProd = (URL.API === InservioPathURL.PRODUCTION) ? true : false;
