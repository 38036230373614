import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-accessdenied',
  templateUrl: './accessdenied.component.html'
})
export class AccessDeniedComponent implements OnInit {

  constructor(

  ) { }

  ngOnInit(): void {

  }
}
