import { AuthGuard } from './../../_guards/auth.guard';
import { LocalStorageService } from 'ngx-localstorage';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import * as ConfigKey from '../../../common/common';
import { RequestService } from '../../../services/requestservice';
import * as APIMethodConfig from '../../../common/apimethod';
@Component({
  selector: 'app-private',
  templateUrl: './private.component.html',
  styleUrls: ['./private.component.scss']
})
export class PrivateComponent implements OnInit {
  fullName: string = '';
  username: string = '';
  VersionList = new Array<any>();
  version: string = '';
  currentyear: string = '';
  customStyleAvatar = {
    backgroundColor: "rgb(13 104 86)",
    border: "1px solid rgb(163 217 119)",
    borderRadius: "50%",
    color: "#ffffff",
    cursor: "pointer"
  };
  constructor(public router: Router,
    private storageService: LocalStorageService,
    private AuthGuard: AuthGuard,
    public requestService: RequestService
  ) { }

  ngOnInit(): void {

    // var wrapper = document.getElementById("wrapper");
    // wrapper.onscroll = function() {myFunction()};
    window.onscroll = function () { myFunction() };
    // Get the navbar
    var navbar = document.getElementById("MenuMainBg");
    var header = document.getElementById("Ins-headersec");
    // Get the offset position of the navbar
    var sticky = navbar.offsetTop;
    // Add the sticky class to the navbar when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar.classList.add("active");
        header.classList.add("sticky");
      } else {
        navbar.classList.remove("active");
        header.classList.remove("sticky");
      }
    }

    //script ends

    var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
    const ToeknData = this.AuthGuard.GetTokenDetail();
    if (ToeknData != null) {
      this.fullName = ToeknData.fullName;
      console.log(this.fullName);
    }
    this.GetInservioversionList();
  }
  resetpassword() {
    const ToeknData = this.AuthGuard.GetTokenDetail();
    if (ToeknData != null) {
      this.username = ToeknData.emailAddress;
    }
    this.router.navigate(['/updatepassword/' + this.username]);
  }
  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {

        this.VersionList = response.entity;
        if (this.VersionList.length > 0) {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }

}
