import { IdealTimerComponent } from './../modules/idealTimer/idealtimer.component';
import { IdleTimerService } from './_services/idletimerservice';
import { PrivateComponent } from './layouts/private/private.component';
import { Component, OnDestroy, OnInit, Inject, ViewChild,HostListener,ElementRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";
import { Subscription } from 'rxjs';
import { filter, first } from "rxjs/operators";
import { DOCUMENT } from "@angular/common";
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
declare function LoadInservioWebScript(): any;
//import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import * as momentJs from 'moment';
import { data } from "jquery";
import * as moment from "moment";
import { LocalStorageService } from 'ngx-localstorage';
import * as ConfigKey from './../common/common';
import { AuthGuard } from './_guards';

@Component({
  selector: 'inservio-app-component',
  templateUrl: './inservioappcomponent.html',
  styles: [
    `.modal{background: rgba(0,0,0, .5)};`
  ]
})
//implements OnInit, OnDestroy
export class InservioAppComponent {
  subscription: Subscription = new Subscription;
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  title = 'angular-idle-timeout';
  display = "none";
  displayQR= 'none';
  clearqr='';
  qrpopupwindowshow=false;
  interval:any;
  @ViewChild('qrcode') searchElement: ElementRef;
  constructor(
    public router: Router,
    @Inject(DOCUMENT) private document: any,
    private idle: Idle, private keepalive: Keepalive,
    private storageService: LocalStorageService, private AuthGuard: AuthGuard,
    private idleService: IdleTimerService
  ) {

    let dashboadlogin = localStorage.getItem("dashboadlogin");
    let isDashboardLogin = Boolean(JSON.parse(dashboadlogin));
    if (!isDashboardLogin) {
      this.logout();
    }
    

    idle.setIdle(1800); // 30 minutes 
    idle.setTimeout(43200); // 12 Hours
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = "I'm back, please"
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.router.navigate(['/login']);
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = 'You\'ve gone idle!'
      var Key = this.storageService.get(ConfigKey.Common.ENCRYPT.LOCALSTORAGEKEY);
      const ToeknData = this.AuthGuard.GetTokenDetail();
      if (ToeknData != null) {
        document.getElementById("openModalButton").click();
      }
    });

    idle.onTimeoutWarning.subscribe((countdown) => {
      var hours = 0;
      var minutes = 0;
      var seconds = 0;
      var xstate = 0;
      if (countdown > 3600) {
        hours = Math.floor(countdown / 3600);
        var remain = countdown % 3600;
        minutes = Math.floor(remain / 60);
        var secremain = remain % 60;
        seconds = secremain;
        xstate = 2;
      }
      else if (countdown > 60) {
        minutes = Math.floor(countdown / 60);
        var secremain = countdown % 60;
        seconds = secremain;
        xstate = 1;
      }
      else {
        seconds = countdown;
      }
      if (xstate == 0) {
        this.idleState = 'You will time out in ' + countdown + ' seconds!'
      }
      else if (xstate == 1) {
        this.idleState = 'You will time out in ' + minutes + ' minutes & ' + seconds + ' seconds!';
      }
      else if (xstate == 2) {
        this.idleState = 'You will time out in ' + hours + ' hours, ' + minutes + ' minutes & ' + seconds + ' seconds!';
      }
    });

    keepalive.interval(3600);
    keepalive.onPing.subscribe(() => this.lastPing = new Date());
    this.idleService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
        this.timedOut = false;
        this.reset();
      } else {
        idle.stop();
        this.reset();
      }
    })
    this.reset();

  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.key === 'F2'){
      this.openModalQR();
    }
    if (event.key === 'Escape' && this.qrpopupwindowshow === true){
      this.onCloseHandledQR();
    }
  }


  onScanQR(qr){        
    console.log('onScanQR change: '+qr);
     this.clearqr = 'Redirecting to..';        
     
     this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>
     this.router.navigate(["/orders/details/"+qr]))
     
     
    
     setTimeout(()=>{    
       this.clearqr = '';
       this.onCloseHandledQR();
       this.searchElement.nativeElement.value = null;
     },2000);    
  }

  openModalQR() {
    this.displayQR = "block";
    this.qrpopupwindowshow = true;
    setTimeout(()=>{ // this will make the execution after the above boolean has changed
      this.searchElement.nativeElement.focus();
    },0);

    //timer to wait for correct GUID from scanner ..until its good it clear and close and redirect
    this.interval = setInterval(()=>{      
      //console.log('It runs until its scanned'+this.searchElement.nativeElement.value)
      if (this.searchElement.nativeElement.value && this.searchElement.nativeElement.value.length==36){
        console.log('Ready to redirect to:'+this.searchElement.nativeElement.value);
        clearInterval(this.interval);
        this.onScanQR(this.searchElement.nativeElement.value)

      }
    },400)
  }
  
  onCloseHandledQR() {
    this.displayQR = "none";
    this.qrpopupwindowshow = false;
    clearInterval(this.interval);
  }




  reset() {
    this.idle.watch();
    this.timedOut = false;
  }

  hideChildModal(): void {
    this.onCloseHandled();
    this.reset();
  }

  stay() {
    this.onCloseHandled();
    this.reset();
  }

  logout() {
    this.onCloseHandled();
    this.idleService.setUserLoggedIn(false);
    this.router.navigate(['/']);
    this.subscription.unsubscribe();
    localStorage.clear();
    this.reset();
  }
  openModal() {
    this.display = "block";
  }
  onCloseHandled() {
    this.display = "none";
  }

}

