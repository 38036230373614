import { RequestService } from './../../services/requestservice';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as APIMethodConfig from '../../common/apimethod';
@Component({
  selector: 'app-updatepassword',
  templateUrl: './updatepassword.component.html',
  styleUrls: ['./updatepassword.component.sass']
})
export class UpdatepasswordComponent implements OnInit {
  
  UserName:string='';
  pswForm:any;
  errorlog:string='';
  SubmitLoader = false;
  currentyear: string = '';
  VersionList = new Array<any>();
  version: string = '';
  constructor(public router: Router,
    public route: ActivatedRoute,
    public requestService:RequestService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.UserName = params['username'];
    });
    if (this.UserName == null || this.UserName=='') {
      this.router.navigate(['/login']);
     }
     this.pswForm = new FormGroup({
      password: new FormControl('', [Validators.required]),
      confirmpassword: new FormControl('', [Validators.required]),
      passwordcurrent:new FormControl('', [Validators.required])
    });
    this.GetInservioversionList();

  }

  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {
  
        this.VersionList = response.entity;
        if(this.VersionList.length>0)
        {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();          
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }
public UpdatePassword(){
  this.SubmitLoader = true;
  if(!this.pswForm.valid){
return;
  }
var formval:any=this.pswForm.value;
if(formval.password==formval.confirmpassword)
{
  this.requestService.HttpPostNParam<any>(APIMethodConfig.InservioAPIMethod.Account.UpdatePassword+"/"+this.UserName+"/"+formval.password+"/"+formval.passwordcurrent ).
  subscribe((response: any) => {
   
    if(response.status)
    {
      this.router.navigate(['/twofactorauth/'+this.UserName]);
    }
    else
    {
      this.errorlog=response.returnMessage[0];
    }
    this.SubmitLoader = false;
  }, (response:any) => this.onFailed(response));

 
}
else{
this.errorlog="Password do not match";
}
  //this.router.navigate(['/resetsuccess']);

}
 public onFailed(response: any) {
    //throw new Error('Method not implemented.');
  }
  

  get f() {
    return this.pswForm.controls;
  }

}
