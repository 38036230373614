import { AlertService } from './../../services/alertservices/alert.service';
import { ResetPasswordModule } from './viewmodels/resetpassword.viewmodel';
import { RequestService } from './../../services/requestservice';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ResetPasswordResponse } from './viewmodels/resetpassword.response';
import { DOCUMENT } from '@angular/common';
import * as APIMethodConfig from '../../common/apimethod';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
})
export class ForgetpasswordComponent implements OnInit {
  formData: any;
  submitted: boolean = false;
  username: string = '';
  message: string = '';
  currentyear: string = '';
  VersionList = new Array<any>();
  version: string = '';
  tableLoader: boolean = false;
  constructor(public router: Router,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private requestService: RequestService,
    private alertService: AlertService,
    @Inject(DOCUMENT) private document: any) { }

  ngOnInit(): void {
    this.resetForm();
    this.GetInservioversionList();

  }
  get f() { return this.formData.controls; }
  private resetForm() {
    this.formData = this.fb.group({
      username: ['', [Validators.required]]
    });
  }

  public onSubmit() {
    this.submitted = true;
    this.tableLoader = true;
    let model = new ResetPasswordModule();
    model.username = this.formData.controls.username.value.trim();
    this.username = model.username;
    model.baseUrl = this.document.location.hostname;
    if (this.formData.valid) {
      this.requestService.HttpPOSTPassword<ResetPasswordModule>(APIMethodConfig.InservioAPIMethod.Account.ForgetPassword, model.username).
        subscribe((response: ResetPasswordResponse) => {
          this.tableLoader = false;
          this.addOrUpdateSuccess(response);
        }, (response: any) => this.addOrUpdateFailed(response));
    }
  }

  public GetInservioversionList() {
    this.requestService
      .HttpGet<any>(APIMethodConfig.InservioAPIMethod.Common.VersionList, null)
      .subscribe((response: any) => {

        this.VersionList = response.entity;
        if (this.VersionList.length > 0) {
          this.version = this.VersionList[0].versionTag;
          var currentTime = new Date();
          this.currentyear = currentTime.getFullYear().toString();
        }
      });
  }
  private addOrUpdateSuccess(response: ResetPasswordResponse) {
    this.message = response.returnMessage;
    if (response.status) {
      this.router.navigate(['/otpverfication/' + this.username + '/' + response.entity]);
    }
  }
  private addOrUpdateFailed(error: HttpErrorResponse) {
    this.tableLoader = false;
    let errorResponse: any = error.error;
    if (error.status > 0) {
      this.alertService.ShowErrorMessage(errorResponse.returnMessage[0]);
    } else {
      this.alertService.ShowErrorMessage(error.message);
    }
  }

  public SendOTPVerification() {

    this.router.navigate(['/otpverfication']);
  }
}
