const Account = {
  Login: '/api/Account/Login',
  Generate2FA: '/api/Account/Generate2FA',
  Verify2FA: '/api/Account/Verify2FA',
  Register: '/api/Account',
  ForgetPassword: '/api/Account/ForgetPassword',
  verifyPassword: '/api/Account/OTPVerfication',
  ResetPassword: '/api/Account/ResetPassword',
  UpdatePassword: '/api/Account/UpdatePassword'
};

const Users = {
  UserList: '/api/InservioUsers',
  RegisterUser: '/api/Account/RegisterUser',
  UserById: '/api/InservioUsers',
  UpdateStatus: '/api/InservioUsers/UpdateUserStatus',
  removeItem: '/api/InservioUsers',//DeleteInservioUser
  UpdateUser: '/api/InservioUsers'
};
const Program = {
  ProgramList: '/api/Program',
  NewProgram: '/api/Program',
  ProgramMaster: '/api/Program/GetProgramMasterList',
  GroupMaster: '/api/Program/GetProgramGroupMasterList',
  UpdateStatus: '/api/Program/UpdateProgramStatus',
  removeItem: '/api/Program',//DeleteInservioRole
  UpdateProgram: '/api/Program',
  ProgramById: '/api/Program',
  ProgramGroupById: '/api/Program/GetProgramGroup',
  UpdateProgramGroup: '/api/Program/PutProgramGroup',
  UpdateGroupStatus: '/api/Program/UpdateProgramGroupStatus',
  removeGroupItem: '/api/Program/DeleteProgramGroup',
  newProgramGroup: '/api/Program/PostProgramGroup',
  GetUnassignedProgramGroupsList: '/api/Program/UnassignedProgramGroups',
}

const Product = {
  ProductVariant: '/api/ProductVariants/GetProductVariants',
  ProdcuctVariantlist: '/api/ProductVariants/GetProdcuctVariantlist',
  ProductGroup: '/api/Products/GetProductsList',
  NewProductVariant: '/api/ProductVariants',
  VariantById: '/api/ProductVariants',
  UpdateProductVariant: '/api/ProductVariants',
  UpdateStatus: '/api/ProductVariants/UpdateProductVariantStatus',
  removeItem: '/api/ProductVariants',
  ProductsGroupList: '/api/Products/GetProductsGroupList',
  ProductCategory: '/api/ProductCategories/GetProductCategories',
  GetProductCategoriesShopingCart: '/api/ProductCategories/GetProductCategoriesShopingCart',
  NewProductGroup: '/api/Products',
  ProductbyId: '/api/Products',
  UpdateProductGroup: '/api/Products',
  UpdateProductStatus: '/api/Products/UpdateProductGroupStatus',
  removeProductGroup: '/api/Products',
  ProductListForcart: '/api/ProductVariants/GetProductVariantByCategories',
  ProductCategoryList: '/api/ProductVariants/GetProductCategories',
  UpdateSecondaryPackedStatus: '/api/OrderSecondaryItems/UpdatePackedStatus',

}

const Roles = {
  RoleList: '/api/Roles',
  RegRole: '/api/Roles/RegisterNewRole',
  RoleById: '/api/Roles',
  UpdateStatus: '/api/Roles/UpdateRoleStatus',
  removeItem: '/api/Roles',//DeleteInservioRole
  UpdateRole: '/api/Roles'
}

const Permission = {
  ActionModule: '/api/RolePermissions/GetActionModulesList'
}

const Patient = {
  Patient: '/api/Patients',
  NewPatientList: '/api/Patients/new/list',
  IsPatientExist: '/api/Patients/IsExistPatient',
  PatientTherapy: '/api/Patients/PostPatientTherapy',
  UpdatePatientTherapy: '/api/patient/UpdatePatientTherapy',
  PateintProgram: '/api/Program/PostPatientPrograms',
  UpdatePatientProgram: '/api/Program/UpdatePatientPrograms',
  PatientAddress: '/api/PatientAddresses',
  RemovePatientprogram: '/api/Program/DeletePatientPrograms',
  GetCheckOutPatientTaskList: '/api/patients/task/checkout/list',
  UpdatePatientStatus: '/api/Patients/UpdatePatientStatus',
  SaveTherpaySceduleAdjustment: '/api/patient/therapy/SaveTherpaySceduleAdjustment',
  GetTherpayPauseSceduleAdjustment: '/api/patient/therapy/TherpayPauseSceduleAdjustment',
  GetPatientTherapyById: '/api/patient/therapy',
  GetPatientTherapyHistoryById: '/api/patient/therapyhistory',
  GetTherpayPatientSceduleTemplateById: '/api/patient/therapy/template',
  UpdateRxonHandTherapySchedulePatient: '/api/patients/therapy/UpdateRxonHandTherapySchedulePatient',
  UpdateTherapyScheduleWithdrawn: '/api/patient/therapy/withdrawn',
  RemoveTherapySchedule: '/api/patients/therapy/delete/scheduletherapy',
  GetFasttrackSceduleAdjustment: '/api/patient/therapy/FasttrackSceduleAdjustment',
  SaveFasttrackSceduleAdjustment: '/api/patient/therapy/SaveFasttrackSceduleAdjustment',
  UpdateOrderAddressid: '/api/patient/order/UpdateOrderAddressid',
  GetOrdersbyTherapyschedulepatient: '/api/patient/therapy/OrderList',
  SaveFasttrackAdjustment: '/api/patient/therapy/SaveFasttrackAdjustment',
  GetTherapyDuedatebyorderid: '/api/patient/therapyduedate',
  GetFasttrac: '/api/patient/therapy/OrderList',
  PatientOriginCombinationList: '/api/patient/program/PatientOriginCombinationList',
  TherapyViewList: '/api/Patients/Gettherapyviewlist',
  GetPatientImportStatus: "/api/Patients/GetPatientImportStatus",
  UpdatePatientSortingPicker: '/api/Patient/UpdatePatientSortingPicker',
  AddPatientTherapy: '/api/Patients/AddTherapy',
  GetTherapySchedulepatienthistory: '/api/patient/therapysceduleHistory',
  CreateNewOrder: '/api/Patients/CreateNewOrder',
  GetOrderPatientProgramsList: '/api/patient/GetOrderPatientProgramsList',
  GetOrderTherapyList: '/api/patient/GetOrderTherapyList',
  AddExistingOrderTherapy: '/api/patient/AddExistingOrderPatientTherapy'
}

const POSProfile = {
  POSProfile: '/api/PointOfSales'
}

const Orders = {
  Order: '/api/OrderDetails',
  SaveOrderShippingDetails: '/api/order/ordershippingdetails',
  UpdateOrderShippingDetails: '/api/order/ordershippingdetails',
  GetOrderShippingDetails: '/api/order/ordershippingdetails',
  GetOrderJourneyDetail: '/api/order/orderjourney',
  UpdateOrderJourneyStatus: '/api/order/UpdateOrderJourneyStatus',
  CreateOrderTaskJourney: '/api/order/create/ordertaskjourney',
  UpdateOrderTaskJourney: '/api/order/update/ordertaskjourney',
  UpdateCurrentAssignment: '/api/order/update/currentassignment',
  UpdateOrderJobid: '/api/order/update/orderjobid',
  Payment: '/api/order/payment',
  UpdateOrderTaskMakeChange: '/api/order/task/makechange',
  OrderDetailChangeDetailRequest: '/api/order/task/OrderDetailChangeDetailRequest',
  CreateBulkOrderTaskJourney: '/api/order/create/bulk/ordertaskjourney',
  UpdateRxDetails: '/api/order/UpdateRxDetails',
  DeleteOrderItems: '/api/OrderDetails/DeleteOrderItems',
  PrintDocket: '/api/Docket',
  PrintLabel:'/api/OrderLabel/pdf',
  PaymentDetailsbyid: '/api/OrderDetails/getpaymentdetailsbyid',
  SaveConfirmationStatus: '/api/order/update/confirmationstatus',
  SaveDispenseStatus: '/api/order/update/dispensestatus',
  GetOrderConfirmationDetails: '/api/order/orderconfirmationdetails',
  GetOrderDispensingDetails: '/api/order/orderdispensedetails',
  SaveAddressChange: '/api/OrderDetails/SaveAddressChange',
  SaveOrderchangerequest: '/api/order/update/orderchangerequest',
  GetOrderchangerequestlist: '/api/order/orderchangerequestlist',
  SaveOrderchangestatusupdate: '/api/order/update/Orderchangestatusupdate',
  GetOrderchangerquestdetailbyid: '/api/order/orderchangerquestdetailbyid',
  UpdateOrderType: '/api/OrderDetails/UpdateOrderDetailType',
  OrderSecondaryItems: '/api/OrderSecondaryItems/PostOrderSecondaryItem',
  GetOrderSecondaryItems: '/api/OrderSecondaryItems/GetOrderSecondaryItems',
  RemoveOrderSecondaryItems: '/api/OrderSecondaryItems/RemoveOrderSecondaryItems',
  UpdateOrderSortingPicker: '/api/order/UpdateOrderSortingPicker',
  Removetherapyfromorder: '/api/order/Removetherapyfromorder',
  UpdateTherapyfromviewschedule: '/api/order/UpdateTherapyfromviewschedule',
  UpdateOrderReferenece: '/api/OrderDetails/UpdateOrderReferenece'
}

const DeliveryProvider = {
  Courier: '/api/DeliveryProviders'
}

const ProgramActivity = {
  ProgramActivityStatus: '/api/ProgramActivity/GetProgramActivityStatus',
  ProgramActivities: '/api/ProgramActivity/GetProgramActivity',
  ProgramActivitySubOption: '/api/ProgramActivity/GetProgramActivitySubOptions',
  ProgramActivityType: '/api/ProgramActivity/GetProgramActivitiesType',
  PostPatientProgramActivities: '/api/ProgramActivity/PostPatientProgramActivities',
  UpdatePatientProgramsActivities: '/api/ProgramActivity/UpdatePatientProgramsActivities',
  DeletePatientProgramsActivity: '/api/ProgramActivity/DeletePatientProgramsActivity'

}

const Media = {
  ActivitiesFile: '/api/Media/ActivitiesFile',
  ProductsFile: '/api/Media/ProductsFile',
  InstitutionFile: '/api/Media/InstitutionFile',
  PatientsBulkImport: '/api/Media/PatientsBulkImport',
  GetUploadedFileTemplate: '/api/Media/GetUploadedFileTemplate',
  GetPatientCSVFileTemplate: '/api/Media/GetPatientCSVFileTemplate',
}

const Task = {
  TaskList: '/api/Task',
  KanbanTaskList: '/api/Task/Kanban/List',
  SaveTask: '/api/Task',
  UpdateTask: '/api/Task',
  DeleteTask: '/api/Task',
  TaskById: '/api/Task',
  UpdateKanbanTaskStatus: '/api/Task/Kanban/Update'
}

const Common = {
  PatientsList: '/api/Common/GetPatients',
  RoleList: '/api/Common/GetRoles',
  ProgramGroupList: '/api/Common/GetProgramGroups',
  ProgramWithdrawTypeList: '/api/Common/GetProgramWithdrawnType',
  TaskList: '/api/Common/GetTasks',
  Roles: '/api/Common/GetRoles',
  Patients: '/api/Common/GetPatients',
  Tasks: '/api/Common/GetTasks',
  Users: '/api/Common/GetUsers',
  Programs: '/api/Common/GetPrograms',
  Therapy: '/api/Common/GetTherappys',
  ProductList: '/api/Common/GetProducts',
  GetPatientAddress: '/api/Common/GetPatientAddress',
  PaymentMethodList: '/api/Common/GetPaymentMethod',
  RXStatusList: '/api/Common/GetRXStatusList',
  VersionList: '/api/Common/GetVersionno',
  ServiceTypeList: '/api/Common/GetOrderServiceType',
  ReasonCodeList: '/api/Common/GetReasonCode',
  ConfirmationStatusList: '/api/Common/GetCofirmationStatus',
  DispensingStatusList: '/api/Common/GetDispensingStatus',
  OrderChangeTypeList: '/api/Common/GetOrderChangeType',
  OrderChangeStatus: '/api/Common/GetChangeRequestStatus',
  SortPickerList: '/api/account/sortpickerlist'
}

const CommunicationNote = {
  CommunicationNotes: '/api/CommunicationNotes'
}

const Therapy = {
  TherapyList: '/api/Patients/GetTherapyScheduledList',
  GenViewModel: '/api/Patients/GetTherapyDumbo',
  GetTherapies: '/api/Therapies',
  TherapiesById: '/api/Therapies',
  NewTherapy: '/api/Therapies',
  UpdateTherapy: '/api/Therapies',
  RemoveTherapy: '/api/Therapies',
  CreateNewTherapyOrders: '/api/patients/therapy/create/order',
  UpdateTherapyStatus: '/api/Therapies/UpdateTherapyStatus',
  TherapyListv1: '/api/Therapies/GetTherapyScheduledListv1'
}
const History = {
  HistoryList: '/api/History',

}

const SmartWay = {
  SmartwayEnvDetail: '/api/Smartway'
}

export const SystemModule =
{
  PatientNoteId: 'f546fdd9-a160-47b8-99f3-e1dab325c11b',
  AddressNoteId: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
  ContactNoteId: '679eeb6b-61e4-409f-94db-b846c8512af1'
}

export const HAPI = {
  PatientsList: '/api/hapi/patients',
  DispenseHistoryList: '/api/hapi/DispenseHistory',
  OrderRXList: '/api/hapi/OrderRX'
}

const Institution = {
  InstitutionList: '/api/Institution',
  removeItem: '/api/Institution',
  removeInstiPresLinking: '/api/Institution/RemoveInstitutionPrescriberLinking',
  InstitutionByid: '/api/Institution',
  Institutionstatuslist: '/api/Institution/GetInstitutionStatus',
  TherapeuticArealist: '/api/Institution/GetTherapeuticArea',
  InstitutionTypelist: '/api/Institution/GetInstitutionType',
  NewInstitution: '/api/Institution',
  UpdateInstitution: '/api/Institution',
  AddInstitutionAddressNote: '/api/Institution/AddInstitutionAddressNote',
  AddUpdateInstitutionPrescriberTherappyLinking: '/api/Institution/AddUpdateInstitutionPrescriberTherappyLinking',
  GetInstitutionPrescriberTherappyLinking: '/api/Institution/GetInstitutionPrescriberTherappyLinking',
  GetInstitutionPrescriberTherappyLinkById: '/api/Institution/GetInstitutionPrescriberTherappyLinkById',
  DeleteInstitutionPrescriberTherapyLinking: '/api/Institution/DeleteInstitutionPrescriberTherapyLinking',
}

const InstitutionContact = {
  InstitutionContactType: '/api/InstitutionContact',
  GetInstitutionContactList: '/api/InstitutionContact/GetInstitutionContactList',
  PostInstitutionContacts: '/api/InstitutionContact',
  RemoveInstitutionContact: '/api/InstitutionContact',
}


const Prescriber = {
  PrescriberList: '/api/Prescriber',
  PrescriberById: '/api/Prescriber',
  PostPrescribers: '/api/Prescriber/PostPrescribers',
  UpdatePrescribers: '/api/Prescriber/UpdatePrescribers',
  SoftDeleteById: '/api/Prescriber/SoftDeleteById',
  PrescriberByInstitutionId: '/api/Prescriber/GetPrescriberListByInstitutionId',
  PostInstitutionPrescribersMapping: '/api/PrescriberMapping',
  UpdatenstitutionPrescribersMapping: '/api/PrescriberMapping',
}

const Tags = {
  GetTags: '/api/Tag/Tags',
  DeleteTags: '/api/Tag/Tags',
  GetPatientTags: '/api/Tag/PatientTags',
  SaveTagMapping: '/api/Tag',

}

export const InservioAPIMethod = {
  Account: Account,
  UserManagement: Users,
  RoleManagement: Roles,
  Programs: Program,
  Therapies: Therapy,
  PatientManagement: Patient,
  Permission: Permission,
  POSProfile: POSProfile,
  Task: Task,
  Common: Common,
  ProgramActivity: ProgramActivity,
  Media: Media,
  CommunicationNote: CommunicationNote,
  Orders: Orders,
  DeliveryProvider: DeliveryProvider,
  History: History,
  SmartWay: SmartWay,
  Products: Product,
  HAPI: HAPI,
  Institution: Institution,
  Prescriber: Prescriber,
  Tags: Tags,
  InstitutionContact: InstitutionContact
};
