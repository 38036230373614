import { LoginComponent } from './../../modules/login/login';
import { PageNotFoundComponent } from './../../common/pagenotfound/pagenotfound';
import { AuthGuard } from './../_guards/auth.guard';
import { SECURE_ROUTES } from './privaterouting';
import { PUBLIC_ROUTES } from './publicrouting';
import { PrivateComponent } from './../layouts/private/private.component';
import { PublicComponent } from './../layouts/public/public.component';
import { Routes, RouterModule } from '@angular/router';
import { NgModule } from "@angular/core";
import { TwofactorauthComponent } from '../../modules/twofactorauth/twofactorauth.component';
import { ForgetpasswordComponent } from '../../modules/forgetpassword/forgetpassword.component';
import { UpdatepasswordComponent } from '../../modules/updatepassword/updatepassword.component';
import { AccessDeniedComponent } from 'inservioweb/modules/accessdenied/accessdenied.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'twofactorauth/:username', component: TwofactorauthComponent },
  { path: 'forgetpassword', component: ForgetpasswordComponent },
  { path: 'updatepassword/:username', component: UpdatepasswordComponent },
  { path: 'updatepassword/:username', component: UpdatepasswordComponent },
  {
    path: '',
    component: PrivateComponent,
    canActivate: [AuthGuard],
    data: { title: "Secure Views" },
    children: SECURE_ROUTES
  },
  {
    path: '',
    component: PublicComponent,
    data: { title: "Public Views" },
    children: PUBLIC_ROUTES
  }, 
  { path: '**',pathMatch: 'full', component:AccessDeniedComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class ApplicationRoutes { }
